import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { MainMenuComponent } from '@pages/main-menu/main-menu.component';
import { SubMenuComponent } from '@pages/main-menu/sub-menu/sub-menu.component';

import { AgendaTreinoComponent } from './pages/agenda-treino/agenda-treino.component';



import { ForcarTrocaSenhaComponent } from './pages/forcar-troca-senha/forcar-troca-senha.component';


import { PrincipalComponent } from './pages/principal/principal.component';
const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [

            {
                path: 'principal',
                component: PrincipalComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'blank',
                component: BlankComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent,
                canActivate: [AuthGuard]
            },

            {
                path: '',
                loadChildren: () => import('./pages/treinador/treinador.module')
                    .then(m => m.TreinadorModule),
                canActivate: [AuthGuard]
            },
            { path: 'agenda-treino', component: AgendaTreinoComponent, canActivate: [AuthGuard] },
           
           

          
            {
                path: 'treinador',
                loadChildren: () => import('./pages/treinador/treinador.module')
                    .then(m => m.TreinadorModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'epoca',
                loadChildren: () => import('./pages/epoca/epoca.module')
                    .then(m => m.EpocaModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'escalao',
                loadChildren: () => import('./pages/escalao/escalao.module')
                    .then(m => m.EscalaoModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'jogador',
                loadChildren: () => import('./pages/jogador/jogador.module')
                    .then(m => m.JogadorModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'clube',
                loadChildren: () => import('./pages/clube/clube.module')
                    .then(m => m.ClubeModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'jogo',
                loadChildren: () => import('./pages/jogo/jogo.module')
                    .then(m => m.JogoModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'unidade-treino',
                loadChildren: () => import('./pages/unidade-treino/unidade-treino.module')
                    .then(m => m.UnidadeTreinoModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'plano-anual',
                loadChildren: () => import('./pages/plano-anual/plano-anual.module')
                    .then(m => m.PlanoAnualModule),
                canActivate: [AuthGuard]
            },
           
           
            {
                path: 'plano-mensal',
                loadChildren: () => import('./pages/plano-mensal/plano-mensal.module')
                    .then(m => m.PlanoMensalModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'plano-semanal',
                loadChildren: () => import('./pages/plano-semanal/plano-semanal.module')
                    .then(m => m.PlanoSemanalModule),
                canActivate: [AuthGuard]
            },

            
            {
                path: 'mensalidade',
                loadChildren: () => import('./pages/mensalidade/mensalidade.module')
                    .then(m => m.MensalidadeModule),
                canActivate: [AuthGuard]
            },
           
            
            {
                path: 'acesso',
                loadChildren: () => import('./pages/acesso/acesso.module')
                    .then(m => m.AcessoModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'historia-jogador',
                loadChildren: () => import('./pages/historia-jogador/historia-jogador.module')
                    .then(m => m.HistoriaJogadorModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'historico-jogo-jogador',
                loadChildren: () => import('./pages/historico-jogo-jogador/historico-jogo-jogador.module')
                    .then(m => m.HistoricoJogoJogadorModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'historico-jogador-mensalidade',
                loadChildren: () => import('./pages/historico-jogador-mensalidade/historico-jogador-mensalidade.module')
                    .then(m => m.HistoricoJogadorMensalidadeModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'historico-jogador-treino',
                loadChildren: () => import('./pages/historico-jogador-treino/historico-jogador-treino.module')
                    .then(m => m.HistoricoJogadorTreinoModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'historico-treinador',
                loadChildren: () => import('./pages/historico-treinador/historico-treinador.module')
                    .then(m => m.HistoricoTreinadorModule),
                canActivate: [AuthGuard]
            },

        ]
    },




    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'recover-password/:token',
        component: RecoverPasswordComponent
    },
    {
        path: 'forcar-troca-senha/:idUsuario',
        component: ForcarTrocaSenhaComponent

    },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
