import { Component, OnInit } from '@angular/core';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // Plugin necessário
import interactionPlugin from '@fullcalendar/interaction'; // Para eventos de clique
import { AppService } from '@services/app.service';
import { UnidadeTreinoService } from '@services/avatarcs/unidade-treino.service';
@Component({
  selector: 'app-agenda-treino',
  templateUrl: './agenda-treino.component.html',
  styleUrls: ['./agenda-treino.component.scss']
})
export class AgendaTreinoComponent implements OnInit {
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth', // Modo de visualização do calendário
    plugins: [dayGridPlugin, interactionPlugin], // Adicione os plugins aqui
    events: [], // Eventos carregados dinamicamente
    editable: true,
    selectable: true,
    dateClick: this.handleDateClick.bind(this), // Evento ao clicar em uma data
    eventClick: this.handleEventClick.bind(this)
  };
  private token: string;
  private idUsuario: string;
  totalRegistros: number;

  constructor(private api: UnidadeTreinoService,
    private app: AppService,
  ) {

    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
  }

  ngOnInit(): void {
    //this.loadEvents(); // Carregar eventos na inicialização
    this.buscaunidade_treino();
  }

  buscaunidade_treino() {
    let datas: { id: string; title: string; date: string; description: string; }[] = [];
    this.api.getTotalunidade_treino(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(res => {
        if (res.status == 'Sucesso') {
          this.totalRegistros = Number(res.data[0].total);
          this.api.getunidade_treinos(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), 0, this.totalRegistros)
            .subscribe(res => {
              if (res.status == 'Sucesso') {
                console.log(res.data)
                const temp = res.data;
                temp.forEach(e => {
                  var treino = {
                    id: String(e.id_unidade_treino),
                    title: 'Treino ' + e.id_unidade_treino,
                    date: e.dt_unidade_treino_sem_formatacao,
                    description: 'Treinador ' + e.nome_treinador
                  }
                  datas.push(treino);
                });
                console.log(datas)
                this.calendarOptions.events =datas;
              }

              else {



              }


            });

        }
        else {

        }
      })


  }



  // Método para carregar os eventos do banco
  loadEvents() {
    this.calendarOptions.events = [
      { id: '1', title: 'Treino 1', date: '2025-02-03', description: 'Treino 1' },
      { id: '2', title: 'Treino 2', date: '2025-02-10', description: 'Treino 2' }
    ];
  }

  handleDateClick(arg: any) {
    alert(`Você clicou na data: ${arg.dateStr}`);
  }
  handleEventClick(eventClickInfo: EventClickArg) {
    alert(`Evento: ${eventClickInfo.event.title}\nDescrição: ${eventClickInfo.event.extendedProps['description']}`);
  }
}
